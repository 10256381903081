<script setup lang="ts">
import { storeToRefs } from 'pinia'

import VText from '~/components/ui/VText.vue'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'
import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type { Locale } from '~/types/locale'

type Link = {
  content: string
  name: string
  target: string | (() => void)
  isExternal?: boolean
  isFunction?: boolean
}

type LinkList = {
  categoryName: string
  links: Link[]
}

const { locale, t } = useI18n()
const route = useRoute()

const { IS_LOGGED_IN } = storeToRefs(useUserStore())
const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const localizedObsesssionLink = computed<string>(() => {
  switch (locale.value as Locale) {
    case 'fr':
      return 'https://obsessions.groover.co/'
    case 'en':
    default:
      return 'https://obsessions.groover.co/en/home-2/'
  }
})

const localizedGrooverBlogLink = computed<string>(() => {
  switch (locale.value as Locale) {
    case 'fr':
      return 'https://blog.groover.co'
    case 'en':
    default:
      return 'https://blog.groover.co/en/'
  }
})

const localizedGrooverBlogSuccessStoriesLink = computed<string>(() => {
  switch (locale.value as Locale) {
    case 'fr':
      return 'https://blog.groover.co/category/success-stories/'
    case 'en':
    default:
      return 'https://blog.groover.co/en/category/success-stories-en/'
  }
})

const localizedGrooverBlogRadioLink = computed<string>(() => {
  switch (locale.value as Locale) {
    case 'fr':
      return 'https://radio.groover.co/'
    case 'en':
    default:
      return 'https://blog.groover.co/en/groover-radio/'
  }
})

const linkList = computed<LinkList[]>(() => [
  {
    categoryName: t('components.shared.footer.explore'),
    links: [
      {
        content: t('components.shared.footer.home'),
        name: 'Homepage', // link names are not translated because they are only used for tracking events
        target: IS_LOGGED_IN.value ? '/band/homepage/' : '/',
      },
      {
        content: t('components.shared.footer.about'),
        name: 'About page',
        target: '/about/',
      },
      {
        content: t('components.shared.footer.faq'),
        name: 'F.A.Q page',
        target: 'https://help.groover.co/',
        isExternal: true,
      },
      {
        content: t('components.shared.footer.jobs'),
        name: 'Welcome To The Jungle page',
        target: 'https://www.welcometothejungle.com/en/companies/groover',
        isExternal: true,
      },
    ],
  },
  {
    categoryName: t('components.shared.footer.artistAccelerator'),
    links: [
      {
        content: t('components.shared.footer.grooverBlog'),
        name: 'Groover blog',
        target: localizedGrooverBlogLink.value,
        isExternal: true,
      },
      {
        content: t('components.shared.footer.successStories'),
        name: 'Success stories',
        target: localizedGrooverBlogLink.value,
        isExternal: true,
      },
      {
        content: t('components.shared.footer.playlists'),
        name: 'Playlists (blog category)',
        target:
          'https://open.spotify.com/user/xa5jpotdh03zb469lofbv9u0m?si=2dc310c60bb747d5',
        isExternal: true,
      },
      {
        content: t('components.shared.footer.grooverRadio'),
        name: 'Groover Radio',
        target: localizedGrooverBlogRadioLink.value,
        isExternal: true,
      },
      {
        content: t('components.shared.footer.grooverObsessions'),
        name: 'Groover Obsessions',
        target: localizedObsesssionLink.value,
        isExternal: true,
      },
    ],
  },
  {
    categoryName: t('components.shared.footer.start'),
    links: [
      ...(!IS_LOGGED_IN.value
        ? [
            {
              content: t('components.shared.footer.signUp'),
              name: 'Sign up',
              target: handleSignUpClick,
              isFunction: true,
            },
            {
              content: t('components.shared.footer.logIn'),
              name: 'Log in',
              target: handleLogInClick,
              isFunction: true,
            },
            {
              content: t('components.shared.footer.applyAsCurator'),
              name: 'Influencer signup',
              target: '/influencer/signup/',
            },
          ]
        : []),
      {
        content: t('components.shared.footer.contactUs'),
        name: 'Contact page',
        target: '/contact/',
      },
    ],
  },
])

function handleExternalLinkClick({ name }: { name: string }): void {
  trackEvent(
    {
      category: 'External Visit',
      action: name,
      origin: 'Footer',
    },
    route,
  )
}

function handleLogInClick(): void {
  LOGIN_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Login',
      action: 'Open',
      label: 'Footer',
    },
    route,
  )
}

function handleSignUpClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Band Signup',
      action: 'Open sign up dialog',
      label: 'Footer',
    },
    route,
  )
}
</script>

<template>
  <div
    class="tw-grid tw-grid-cols-1 tw-gap-10 sm:tw-grid-cols-3 600:tw-grid-cols-4"
  >
    <div
      v-for="{ categoryName, links } in linkList.slice(0, 3)"
      :key="categoryName"
      class="category tw-space-y-4"
    >
      <VText cfg="sans/14/medium" color="white">
        {{ categoryName }}
      </VText>
      <div class="divider" />
      <div
        v-for="(
          { content, isExternal, isFunction, name, target }, index
        ) in links"
        :key="index"
      >
        <a
          v-if="isExternal && typeof target === 'string'"
          :href="target"
          target="_blank"
          @click="handleExternalLinkClick({ name })"
        >
          {{ content }}
        </a>
        <div
          v-else-if="isFunction && typeof target === 'function'"
          class="tw-cursor-pointer"
          @click="target"
        >
          {{ content }}
        </div>
        <NuxtLinkLocale v-else :to="target">{{ content }}</NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.category,
.linksList {
  @apply tw-flex tw-flex-col tw-text-center;
  @screen sm {
    @apply tw-text-left;
  }
}
.divider {
  @apply tw-mx-auto tw-h-px tw-w-10 tw-bg-white;
  @screen sm {
    @apply tw-mx-0;
  }
}
</style>
